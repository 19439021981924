//@ts-check
import React from "react"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import Navbar from "../components/LinkedInPowertools/NavbarLIP"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"

import ContactForm from "../components/Contact/ContactForm"
const Contact = () => {
  return (
    <AuthWrapper>
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle="Contact"
          homePageText="Home"
          homePageUrl="/"
          activePageText="Contact"
        />
        {
          //<ContactInfo />
        }
        <ContactForm />
        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default Contact
